/*----- 19. Product details page  ---*/

.product-details-img {
  @media #{$lg-layout} {
    &.mr-20 {
      margin-right: 0;
    }
  }
  @media #{$md-layout} {
    &.mr-20 {
      margin-right: 0;
    }
  }
  @media #{$xs-layout} {
    &.mr-20 {
      margin-right: 0;
    }
  }

  .large-img-style {
    position: relative;
    img {
      width: 100%;
    }
    span {
      position: absolute;
      top: 30px;
      left: 30px;
      font-size: 13px;
      color: #fff;
      display: inline-block;
      line-height: 1;
      padding: 3px 11px;
      border-radius: 3px;
      font-weight: 500;
      background-color: #fa6bff;
    }
    .img-popup-wrap {
      position: absolute;
      right: 30px;
      top: 30px;
      font-size: 30px;
      color: #000000;
      &:hover {
        color: $theme-color;
      }
    }
  }
  .shop-details-tab {
    display: flex;
    justify-content: center;
    margin-top: -72px;
    z-index: 9;
    position: relative;
    @media #{$md-layout} {
      margin-top: -50px;
    }
    @media #{$xs-layout} {
      margin-top: -45px;
    }
    @media #{$sm-layout} {
      margin-top: -60px;
    }
    a.shop-details-overly {
      position: relative;
      img {
        width: 144px;
        @media #{$md-layout} {
          width: 90px;
        }
        @media #{$xs-layout} {
          width: 85px;
        }
        @media #{$sm-layout} {
          width: 110px;
        }
      }
      &.active:before {
        opacity: 0.6;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        content: "";
      }
    }
  }
}


/* product details 2 */


.sidebar-active {
  @media #{$xs-layout} {
    & .product-details-content {
      margin-top: 0;
    }
  }
  &.col-lg-6 {
    padding-left: 15px;
    padding-right: 15px;
    &.is-affixed {
      padding-left: 0px;
      padding-right: 0px;
      & .inner-wrapper-sticky {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

.product-details-gallery {
  @media #{$md-layout} {
    &.mr-20 {
      margin-right: 0;
    }
  }
  @media #{$xs-layout} {
    &.mr-20 {
      margin-right: 0;
    }
  }
}

/* affiliate */


.product-details-slider-active {
  &.nav-style-1.owl-carousel {
    .owl-nav {
      div {
        color: #333;
        font-size: 20px;
        left: 20px;
        &:hover {
          color: $theme-color;
        }
        &.owl-next {
          left: auto;
          right: 20px;
        }
      }
    }
  }
  &:hover.nav-style-1.owl-carousel > .owl-nav div {
    opacity: 1;
    visibility: visible;
  }
}

.product-large-image-wrapper {
  
  .ht-swiper-button-nav {
    background: none;
    border: none;
    color: #D29D77!important;
    height: 44px!important;
    width: 44px!important;
    i {
      font-size: 40px;
    }
  }
  position: relative;
  .product-img-badges {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 3;
    span {
      font-size: 13px;
      color: #fff;
      display: block;
      line-height: 1;
      padding: 3px 11px;
      border-radius: 3px;
      font-weight: 500;
      margin-bottom: 10px;
      &.pink {
        background-color: #fa6bff;
      }
      &.purple {
        background-color: #a749ff;
      }
    }
  }

  &--slider {
    .ht-swiper-button-nav {
      background: none;
      border: none;
      font-size: 50px;
      padding: 0;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      color: #333;
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .single-image {
    img {
      width: 100%;
    }
  }
}

.shop-area {
  .sticky {
    top: 70px !important;
  }
}

.product-small-image-wrapper {
  .ht-swiper-button-nav {
    background: none;
    border: none;
    visibility: hidden;
    opacity: 0;
    color: #333;
    i {
      font-size: 40px;
    }
  }
  .single-image {
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  &:hover {
    .ht-swiper-button-nav {
      visibility: visible;
      opacity: 1;
    }
  }
  .single-image {
    height: 100%;
    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .swiper-container-vertical {
    height: 100%;
  }

  &--side-thumb {
    height: 630px;
    overflow: hidden;
    @media #{$lg-layout, $md-layout, $xs-layout} {
      height: auto;
      margin-top: 15px;
    }
  }
}
