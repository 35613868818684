@font-face {
    font-family: MarketFresh;
    src: url('../fonts/Market\ Fresh.ttf');
    font-display: swap;
}
@font-face {
    font-family: MarketFreshAllCaps;
    src: url('../fonts/Market\ Fresh\ ALL\ CAPS.ttf');
    font-display: swap;
}
@font-face {
    font-family: AngelFace;
    src: url('../fonts/Angelface.otf');
    font-display: swap;
}
@font-face {
    font-family: DMSerifDisplay-Regular;
    src: url('../fonts/DMSerifDisplay-Regular.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'OpenSansBold';
    src: url('../fonts/OpenSans-Bold.ttf');
    font-display: swap;
}
@font-face {
    font-family: 'OpenSansLight';
    src: url('../fonts/OpenSans-Light.ttf');
    font-display: swap;
}


// Font Family
$marketfresh: 'MarketFresh', sans-serif;
$marketfreshallcaps: 'MarketFreshAllCaps', sans-serif;
$angelface:'AngelFace', sans-serif;
$dmserifdisplay: 'DMSerifDisplay-Regular', serif;
$opensans-bold: 'OpenSansBold', sans-serif;
$opensans-light: 'OpenSansLight', sans-serif;
// text Colors ---------------

$white: #ffffff;
$black: #000000;
$grey: #f6f7f8;
$red: #f6f7f8;
$green: #f6f7f8;

// bg Colors ---------------

$bg-white: #ffffff;
$bg-black: #000000;
$bg-grey: #f6f6f8;
$bg-purple: #f0e0ff;

// Heading Color
$heading-color: #000000;

// Theme Color
$theme-color: #D29D77;
$secundary-color: #EFCBBF;
$third-color:#878787;  

// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";
